import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Col, Row } from 'react-bootstrap';
import { WWSet } from '../../../modules/ww/common/components/ww-set';

const WWGuidesEchoesSets: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Echoes Sets</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_echo4.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Echoes Sets</h1>
          <h2>List of all available Echoes Sets in Wuthering Waves.</h2>
          <p>
            Last updated: <strong>26/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Echo Set Bonuses" />
        <Row xs={1} xxl={2} className="relic-set-container">
          <Col>
            <WWSet name="Freezing Frost" mode="card" />
          </Col>
          <Col>
            <WWSet name="Molten Rift" mode="card" />
          </Col>
          <Col>
            <WWSet name="Void Thunder" mode="card" />
          </Col>
          <Col>
            <WWSet name="Sierra Gale" mode="card" />
          </Col>
          <Col>
            <WWSet name="Celestial Light" mode="card" />
          </Col>
          <Col>
            <WWSet name="Havoc Eclipse" mode="card" />
          </Col>
          <Col>
            <WWSet name="Rejuvenating Glow" mode="card" />
          </Col>
          <Col>
            <WWSet name="Moonlit Clouds" mode="card" />
          </Col>
          <Col>
            <WWSet name="Endless Resonance" mode="card" />
          </Col>
          <Col>
            <WWSet name="Frosty Resolve" mode="card" />
          </Col>
          <Col>
            <WWSet name="Eternal Radiance" mode="card" />
          </Col>
          <Col>
            <WWSet name="Midnight Veil" mode="card" />
          </Col>
          <Col>
            <WWSet name="Empyrean Anthem" mode="card" />
          </Col>
          <Col>
            <WWSet name="Tidebreaking Courage" mode="card" />
          </Col>
          <Col>
            <WWSet name="Gusts of Welkin" mode="card" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesEchoesSets;

export const Head: React.FC = () => (
  <Seo
    title="Echoes Sets | Wuthering Waves | Prydwen Institute"
    description="List of all available Echoes Sets in Wuthering Waves."
    game="ww"
  />
);
